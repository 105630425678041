.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  color: white;
  align-items: center;
  margin-bottom: 1vh;
  justify-content: center;
  pointer-events: visible;
  flex-direction: column;
  /* transition: all 0.3s ease-in-out; */
  /* pointer-events: none; */
}

.modal-content {
  width: 80vw;
  padding: 3vw;
  border-radius: 12px;
  background: linear-gradient(180deg, #851956 0%, hsl(276, 55%, 30%) 100%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.79);
  /* transition: all 0.3s ease-in-out; */
  /* transform: translateY(-200px); */
}
